import React, {useEffect} from "react"
import Layout from '@components/layout/layout.jsx'
import DocumentHeadMeta from '@components/utility/SEO.jsx'
import { StaticImage, getImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import './styles.scss'
//Components
import SubNav from '@components/subNav/subNav.jsx'
import Hero from '@components/hero/hero.jsx'
import Portrait from '@components/portrait/portrait.jsx'
import Accordion from "@components/accordion/Accordion.jsx"


// markup
const CareForYourHealthPage = ({ data }) => {
  const DinaPortrait = getImage(data.portraitDina)
  const NasPortrait = getImage(data.portraitNas)
  const HeroImage = getImage(data.hero)

  useEffect(() => {
      document.querySelector('body').addEventListener('click', (e) => {
        document.getElementById(e.target?.href?.split("#")[1])?.focus();
        }
      )
  }, []);

  return (
    <Layout addedClass="page--care-for-your-health">
      <DocumentHeadMeta title='Managing Lupus Nephritis | ALL IN&reg; for Lupus Nephritis' description='Find out what you can do to manage lupus nephritis, including working with your healthcare team, practicing healthy habits, incorporating a kidney-friendly diet, caring for your mental health, and more.' canonicalURL='https://www.allinforlupusnephritis.com/managing-lupus-nephritis' />
      <SubNav>
        <AnchorLink to="/managing-lupus-nephritis#Managing-Your-Health">Managing Your Health</AnchorLink>
        <AnchorLink to="/managing-lupus-nephritis#Care-For-Your-Mental-Health">Care For Your Mental Health</AnchorLink>
      </SubNav>
      <Hero heroImage={HeroImage} imgAlt={""}>
            <h1>Taking Care of Your Health</h1>
            <p><strong>See what you can do to help your physical and mental health beyond medication.</strong></p>
      </Hero>
      <section tabIndex="-1" id="Managing-Your-Health" className="bg-white">
        <div className="container container--narrow">
          <h2>Self-care and lupus nephritis</h2>
          <p>Medication is essential to managing lupus nephritis, but making changes to your lifestyle can also help you better manage the disease. It's important to work closely with <AnchorLink to="/diagnosis-and-treatment#Your-Healthcare-Team" className="link--purple">your healthcare team</AnchorLink> to ensure you have a well-rounded approach to your health and well-being. Remember to always speak with your doctor before making any changes to your diet or exercise routine.</p>
        </div>
      </section>
      <section className="bg-light-purple">
        <div className="container container--narrow">
          <Portrait name="Dina" description="Diagnosed with lupus nephritis in 1994" imgSrc={DinaPortrait} imgAlt=""></Portrait>
          <p>"It is important to take care of oneself in every way possible—physically, emotionally, spiritually, nutritionally..."</p>
        </div>
      </section>
      <section className="bg-white">
        <div className="container container--narrow container--diet">
          <h2>Incorporate a kidney-friendly diet</h2>
          <p>Eating healthy, kidney-friendly foods may help you manage kidney disease and lower blood pressure. Before making any changes to your lifestyle, care, or diet, be sure to talk with your doctor. Some people also choose to seek advice from a licensed dietitian or nutritionist who can help with meal plans and recommend beneficial diet changes.</p>
          <p>Below are some recipes and nutrition tips to help you manage kidney disease.</p>
          <h3>Recipes for kidney health:</h3>
          <Accordion title="Chipotle Shrimp Tacos" subTitle="45 mins prep time&nbsp;|&nbsp;dairy free &amp; gluten-free ">

            <div className="container--split">
              <div className="split-item">
                <p>Ingredients</p>
                <p>Pineapple salsa</p>
                  <ul>
                    <li>1&nbsp;cup pineapple, chopped</li>
                    <li>2&nbsp;tbsp green onions, chopped</li>
                    <li>1&nbsp;tbsp red onion, chopped</li>
                    <li>2&nbsp;tbsp cilantro, chopped</li>
                    <li>1&nbsp;tbsp lime juice</li>
                  </ul>
                <p>Shrimp tacos</p>
                  <ul>
                    <li>1/4&nbsp;cup olive oil</li>
                    <li>2 cloves garlic, chopped</li>
                    <li>1 1/2&nbsp;tbsp Southwest Chipotle Mrs. Dash</li>
                    <li>1 1/2 pound shrimp (approximately 32 medium-sized shrimp), cooked, no tails, peeled, 		deveined, thawed (if frozen)</li>
                    <li>4 corn tortillas, taco size</li>
                    <li>2&nbsp;cups cabbage, shredded</li>
                  </ul>
              </div>
              <div className="split-item">
                <StaticImage src="../../images/care-for-your-health/recipe-shrimp-tacos.jpg" className="" quality="85" placeholder="NONE" alt="" width={500}></StaticImage>
              </div>
            </div>
            <div className="directions">
            <p>Directions</p>
              <ol className="directions-list">
                <li>In medium bowl, mix together olive oil, garlic and Southwest Chipotle Mrs. Dash. 	Toss shrimp into this mixture, coating shrimp well. Set aside to marinate while you put salsa together. Shrimp mixture can also be made ahead of time and marinated in refrigerator.</li>
                  <li>Mix pineapple salsa ingredients in a small bowl. Cover and set aside.</li>
                  <li>Heat skillet over medium heat. Add shrimp mixture to pan and cook approximately 4&nbsp;minutes, turning shrimp once, until heated through. (Shrimp can also be grilled in a vegetable type grill basket). Assemble tacos by dividing shrimp mixture among the 4&nbsp;tortillas. Top with 1/4&nbsp;cup shredded cabbage. And finally top each taco with 1/4 of the salsa mixture. Each taco will be overstuffed.</li>
              </ol>
            </div>
              <a data-modal-id='exit-modal' href="https://www.kidney.org/content/chipotle-shrimp-tacos">See the full recipe</a>
          </Accordion>
          <Accordion title="Easy Crock Pot Pulled Pork" subTitle="30 mins prep time&nbsp;|&nbsp;high protein &amp; low fat">
                <div className="container--split">
                  <div className="split-item">
                    <p>Ingredients</p>
                    <ul>
                      <li>1tsp onion powder</li>
                      <li>1tbsp olive or canola oil</li>
                      <li>1tsp garlic powder</li>
                      <li>1tsp brown sugar</li>
                      <li>1tbsp dry mustard</li>
                      <li>1tsp paprika</li>
                      <li>1 medium onion, minced</li>
                      <li>3 garlic cloves, minced</li>
                      <li>2&nbsp;tbsp tomato paste</li>
                      <li>2&nbsp;tbsp prepared brown mustard</li>
                      <li>1tsp Worcestershire sauce</li>
                      <li>1tbsp brown sugar or molasses</li>
                      <li>½ tsp black pepper</li>
                      <li>¼&nbsp;cup cider vinegar</li>
                      <li>2 pounds pork roast</li>
                      <li>BBQ Sauce</li>
                      <li>8 Hamburger buns</li>
                    </ul>
                  </div>
                  <div className="split-item">
                    <StaticImage src="../../images/care-for-your-health/recipe-pulled-pork.jpg" className="" quality="85" placeholder="NONE" alt="" width={500}></StaticImage>
                  </div>
                </div>
                <div className="directions">
                <p>Directions</p>
                  <ol className="directions-list">
                    <li>Measure all dry ingredients in a small bowl and mix well.</li>
                    <li>Coat the roast with oil.</li>
                    <li>Then rub dry mixture to coat the roast.</li>
                    <li>Place roast into slow cooker and set for 8-10&nbsp;hours.</li>
                  </ol>
                </div>
              <a data-modal-id='exit-modal' href="https://www.kidney.org/content/easy-crock-pot-pulled-pork">See the full recipe</a>
          </Accordion>
          <Accordion title="Pumpkin Cream Cheese Muffins" subTitle="25 mins prep time&nbsp;|&nbsp;low calorie &amp; low sodium">
              <div className="container--split">
                <div className="split-item">
                  <p>Ingredients</p>
                  <ul>
                    <li>8 oz cream cheese</li>
                    <li>3&nbsp;eggs</li>
                    <li>3&nbsp;tbsp Stevia</li>
                    <li>1¾&nbsp;cup sugar</li>
                    <li>2&nbsp;cups flour</li>
                    <li>1&nbsp;tbsp cinnamon</li>
                    <li>2 tsps baking powder</li>
                    <li>¼ tsp baking soda</li>
                    <li>1¼&nbsp;cups pumpkin puree</li>
                    <li>⅓&nbsp;cup apple sauce</li>
                    <li>2 tsp vanilla extract</li>
                  </ul>
                </div>
                <div className="split-item">
                  <StaticImage src="../../images/care-for-your-health/recipe-pumpkin-muffins.jpg" className="" quality="85" placeholder="NONE" alt="" width={500}></StaticImage>
                </div>
              </div>
              <div className="directions">
              <p>Directions</p>
              <ol className="directions-list">
                <li>Heat oven to 375 F.</li>
                <li>Line muffin tins with paper cups and set aside.</li>
                <li>Mix cream cheese, 1 egg, Stevia (about 3&nbsp;tbsp) together, and set aside.</li>
                <li>In a mixing bowl combine 2&nbsp;eggs, pumpkin, apple sauce, and vanilla extract.</li>
                <li>Sift your dry ingredients together sugar (about 1.75&nbsp;cups), flour, cinnamon, baking powder, and baking soda.</li>
                <li>Pour your dry ingredients into your pumpkin mixture and don't over mix your batter. It just needs to be moist.</li>
                <li>Spoon half your batter into 24&nbsp;muffin&nbsp;cups. Spoon a dollop of cream cheese mixture on top of the pumpkin mixture in each&nbsp;muffin&nbsp;cup, and then top each with the rest of the pumpkin mixture.</li>
                <li>Bake for 20 to 25&nbsp;minutes or until a toothpick comes out clean.</li>
              </ol>
              </div>
              <a data-modal-id='exit-modal' href="https://www.kidney.org/content/pumpkin-cream-cheese-muffins">See the full recipe</a>
          </Accordion>
          <a data-modal-id='exit-modal' href="https://www.kidney.org/nutrition" className="cta cta--body--extra-wide cta--body cta--purple">Find more recipes that work for you</a>
        </div>
      </section>
      <section className="bg-light-blue">
        <div className="container">
          <h2>Nutrition tips</h2>
          <div className="container--split container--nutrition">
            <div className="split-block--50">
              <div className="split-block__item">
                <div className="icon"><StaticImage src="../../images/care-for-your-health/icon-salt.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text"><p className="title">Choose food with less salt and sodium if you have high blood pressure</p></div>
              </div>
              <div className="split-block__item">
                <div className="icon"><StaticImage src="../../images/care-for-your-health/icon-burger.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <p className="title">Avoid processed food</p>
                </div>
              </div>
            </div>
            <div className="split-block--50">
              <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/care-for-your-health/icon-fish.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <p className="title">Eat small portions of protein-rich foods like chicken and fish</p>
                </div>
              </div>
              <div className="split-block__item">
                <div className="icon"><StaticImage src="../../images/care-for-your-health/icon-apple.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <p className="title">Choose heart-healthy foods like fruit and vegetables</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="container container--narrow container--exercise">
          <h2>Add exercise to your routine</h2>
          <p>Exercise can have many emotional and physical benefits. It can help reduce stress and strengthen parts of the body that lupus and lupus nephritis affect. You'll want to focus on low-impact exercises to strengthen your bones and muscles without aggravating inflamed joints. Talk with your doctor to figure out the best way to include exercise into your daily or weekly routines.</p>
          <h2>Low impact activities include:</h2>
        </div>
        <div className="container container--narrow container--split container--activities">
          <div className="split-block--40">
              <div className="split-block__item">
                <div className="icon"><StaticImage src="../../images/care-for-your-health/icon-walking.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text"><p className="title">Walking</p></div>
              </div>
              {/* TODO CHANGE ICON */}
              <div className="split-block__item">
                <div className="icon"><StaticImage src="../../images/care-for-your-health/icon-swimming.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <p className="title">Swimming</p>
                </div>
              </div>
            </div>
            <div className="split-block--40">
              <div className="split-block__item split-block__item--align-top">
                <div className="icon"><StaticImage src="../../images/care-for-your-health/icon-yoga.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <p className="title">Stretching or yoga</p>
                </div>
              </div>
              <div className="split-block__item">
                <div className="icon"><StaticImage src="../../images/care-for-your-health/icon-equipment.png" className="" quality="100" placeholder="NONE" alt="" width={70} ></StaticImage></div>
                <div className="text">
                  <p className="title">Using exercise equipment, such as an elliptical, if available</p>
                </div>
              </div>
            </div>
        </div>
      </section>
      <section className="bg-white" style="padding-bottom:0;">
        <div className="container">
          <h2>Looking for ways to be more involved in your care?</h2>
          <p className="less-margin">Find resources, online communities, and advocacy groups that can help.</p>
          <Link to="/resources" className="cta--purple cta--body">Access resources</Link>
        </div>
      </section>
      <section tabIndex="-1" id="Care-For-Your-Mental-Health" style="padding-top:0;" className="bg-white"></section>
      <section className="bg-white">
        <div className="container container--split container--mental-health">
          <div className="split-item mental-health__text">
            <h2>Care for your mental health</h2>
            <p>It's okay to feel unhappy, sad, or frustrated at times as you navigate life with lupus nephritis. That's why caring for your mental health along the way is so important. </p>
            <h2>Consider these ideas to help manage stress and negative feelings:</h2>
          </div>
          <div className="split-item mental-health__image">
            <StaticImage src="../../images/care-for-your-health/mental-health.png" className="" quality="90" placeholder="NONE" alt="" width={659}></StaticImage>
          </div>
        </div>
        <div className="container container--narrow container--manage-mental-health">
          <h3>Practice meditation</h3>
          <p>Mindfulness meditation involves focusing on the present moment with an attitude of acceptance. For people living with chronic pain, mindfulness meditation has been shown to decrease pain.</p>
          <p>Here are some tips for getting started with mindfulness meditation:</p>
          <ul className="standard-list">
            <li>Take a seat somewhere that feels calm and quiet</li>
            <li>Make sure you're in a comfortable and stable position</li>
            <li>Focus on the movement of your breath</li>
            <li>It's OK if your mind wanders. When you find it wandering, gently steer it back to the present moment</li>
            <li>You can also develop visualization exercises: imagining negative or distracting thoughts as clouds or balloons that you watch float away</li>
          </ul>
        </div>
      </section>
      <section className="bg-light-purple">
        <div className="container container--narrow">
          <h2>Living with a medical condition is stressful for both the diagnosed person and their loved ones. Beyond My Battle is dedicated to helping reduce this stress.</h2>
          <a data-modal-id='exit-modal' href="https://www.beyondmybattle.org" className="cta--purple cta--body">Find support</a>
        </div>
      </section>
      <section className="bg-white">
        <div className="container container--narrow container--manage-mental-health">
          <h3>Improve your sleep</h3>
          <p>Difficulty sleeping has been linked to chronic diseases. Not getting enough sleep can contribute to stress, anxiety, and symptoms of clinical depression.</p>
          <p>Trying these tips may help you get better sleep at night:</p>
          <ul className="standard-list">
            <li>Do some type of exercise regularly as recommended by your doctor. Getting your workout in 5-6&nbsp;hours before bed gives your body enough time to relax and improve your sleep</li>
            <li>Avoid caffeine, nicotine, and alcohol for several&nbsp;hours before bedtime</li>
            <li>Make your bedroom sleep-friendly with a comfortable mattress and bedding, the right amount of darkness, and a temperature that's a little bit on the cooler side</li>
            <li>It's OK if your mind wanders. When you find it wandering, gently steer it back to the present moment</li>
            <li>Try to avoid the use of electronic devices a couple of&nbsp;hours before bedtime</li>
          </ul>
        </div>
      </section>
      <section className="bg-purple">
        <div className="container container--narrow">
          <StaticImage src="../../images/care-for-your-health/sleep.png" className="" quality="85" placeholder="NONE" alt="" width={105} ></StaticImage>
          <h2>Talk to your doctor if you're having difficulty sleeping or if you notice any changes in your sleep.</h2>
        </div>
      </section>
      <section className="bg-light-purple">
        <div className="container container--narrow">
          <Portrait name="Nas" description="Diagnosed with lupus nephritis in 2017" imgSrc={NasPortrait} imgAlt=""></Portrait>
          <p>"I have to listen to my body, which is something my mom always told me, listen to your body because you know when you're tired. Don't push yourself past where you can go."</p>
        </div>
      </section>
      <section className="bg-white">
        <div className="container container--narrow container--manage-mental-health">
          <h3>Consider how a therapist may help</h3>
          <p>Many people living with chronic pain find that it takes a toll on their mental health, too. A psychologist can provide emotional support as you cope with lupus nephritis. If you think you may benefit from working with one, your primary care doctor can help you find or recommend local mental health professionals near you.</p>
        </div>
        <div className="container container--narrow container--manage-mental-health">
          <h3>Build up your support system</h3>
          <p>Keep in contact with helpful and supportive family members, work friends, or long-time pals. Grab the phone, contact friends, and set up a video conference with people who care.</p>
          <p className="add-margin--top add-margin--bottom">
          You could also consider joining the <a href="https://www.facebook.com/ALLINforLN/" className="link--purple" data-modal-id='exit-modal'>ALL&nbsp;IN&nbsp;for&nbsp;LN</a> community on Facebook to connect with others who are affected by lupus nephritis.
          </p>
        </div>
      </section>
      <section className="bg-light-purple">
        <div className="container container--split">
          <div className="split-item">
            <StaticImage src="../../images/appointment-icon.png" className="split-item__icon" quality="90" placeholder="NONE" alt="" width={214}></StaticImage>
            <h2>Consult with your doctor before making any lifestyle changes.</h2>
            <p>Use this discussion guide to help you at  your next appointment.</p>
            <Link to="/resources" className="cta--purple cta--body">Download guide</Link>
          </div>
          <div className="divider"></div>
          {/* TODO Missing in Manuscript */}
          <div className="split-item">
            <StaticImage src="../../images/kit-icon.png" className="split-item__icon" quality="90" placeholder="NONE" alt="Image of ALL IN&reg; Lupus Nephritis Awareness Kit" width={216}></StaticImage>
            <h2>Find more information and tools.</h2>
            <p>To help you better manage lupus nephritis.</p>
            <Link to="/resources" className="cta--purple cta--body">Get resources</Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default CareForYourHealthPage

export const pageQuery = graphql`
  query CareForYourHealthQuery {
    portraitDina: file(relativePath: {eq: "portrait-Dina.png"}) {
      childImageSharp {
        gatsbyImageData(height:240)
      }
    }
    hero: file(relativePath: {eq: "care-for-your-health/hero-care-for-your-health.png"}) {
        childImageSharp {
          gatsbyImageData
        }
      }
      portraitNas: file(relativePath: {eq: "portrait-Nas.png"}) {
        childImageSharp {
          gatsbyImageData(height:240)
        }
      }
  }
`
